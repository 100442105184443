import React from "react";
import PropTypes from "prop-types";
import styles from "./Chips.module.css";

const ChipRed = ({ text }) => {
  return (
    <div className={styles.chipRedContainerStyle}>
      <p className={styles.chipRedTextStyle}>{text}</p>
    </div>
  );
};

ChipRed.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ChipRed;
