// hooks/useLightBox.js
import { useState, useCallback } from "react";

const useLightBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(null); // Renamed setter function

  const openLightBox = useCallback((image) => {
    setImage(image); // Now correctly sets the image
    setIsOpen(true);
  }, []);

  const closeLightBox = useCallback(() => {
    setImage(null);
    setIsOpen(false);
  }, []);

  return { isOpen, image, openLightBox, closeLightBox };
};

export default useLightBox;
