import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

// Styles sheet
import "./Navbar.css";

// Assets
import Cv from "../../assets/img/Jayvin Carl Muring CV.pdf";
import Logo from "../../assets/img/Logo.svg";
import OpenMenu from "../../assets/img/Open.svg";
import CloseMenu from "../../assets/img/Close.svg";

function Navbar() {
  // Template of the Gmail.
  const email = "jayvincarlismael@gmail.com";
  const subject = "Inquiry: Let's Discuss Collaboration";
  const body = "";

  // This is the (Gmail) links for desktop and mobile.
  // It is intentionally seperated to avoid the reduncacy for different device.
  const gmailHref = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  const mobileGmailHref = `intent://send?to=${email}&subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(
    body
  )}#Intent;scheme=mailto;package=com.google.android.gm;end;`;

  // This is used to avoid uncessary recreation when it is clicked.
  // This check if it is in mobile devices that are mentioned.
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|BlackBerry|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  }, []);
  



  // This is the function for toggling the menu.
  // If you click it then open the menu.
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);

  // This is the function for links at menu, that when you click it will closed.
  // This it works only for small screens.
  const handleLinkClick = useCallback(() => {
    if (window.innerWidth >= 992) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }, []);




  // This is the function of for handling the scroll for headers effect.
  // If the current scroll is less than 96, then hide the header effect false.
  const [isVisible, setIsVisible] = useState(true);
  const handleScroll = useCallback(() => {
    let currentScrollTop = window.scrollY;
    setIsVisible(currentScrollTop <= 96);
  }, []);

  // This is used to avoid recreating uncessary scroll effect in header.
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);




  // This is the funtion for handling screen size overflow on menu.
  const handleResize = useCallback(() => {
    if (window.innerWidth >= 992) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = menuOpen ? "hidden" : "";
    }
  }, [menuOpen]);

  // This is used to avoid recreating of overflows elements.
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overflow = "";
    };
  }, [handleResize, menuOpen]);

  return (
    <header className={`header ${isVisible ? "visible" : "hidden"}`}>
      <nav className={`nav  ${isVisible ? "" : "scroll"}`}>
        <div className="nav-container">
          <div className="nav-logo-container">
            <Link
              to="/"
              className="logo-link"
              onClick={() => {
                scroll.scrollToTop({ behavior: "smooth", passive: true });
                handleLinkClick();
              }}
            >
              <img src={Logo} alt="Logo" loading="lazy" />
            </Link>
          </div>

          <div className="menu-btn" onClick={toggleMenu}>
            <div
              className="open-menu"
              id="openMenu"
              style={{ display: menuOpen ? "none" : "flex" }}
            >
              <img src={OpenMenu} alt="Open" />
            </div>
            <div
              className="close-menu"
              id="closeMenu"
              style={{ display: menuOpen ? "flex" : "none" }}
            >
              <img src={CloseMenu} alt="Close" />
            </div>
          </div>

          <div className={`menu-container ${menuOpen ? "open" : "closed"}`}>
            <ul className="nav-list">
              <li className="nav-items">
                <ScrollLink
                  href="#fw"
                  to="fw"
                  smooth={true}
                  duration={1000}
                  offset={-96}
                  className="nav-links"
                  onClick={handleLinkClick}
                  tabIndex={0}
                >
                  Work
                </ScrollLink>
              </li>
              <li className="nav-items">
                <ScrollLink
                  href="#ab"
                  to="ab"
                  smooth={true}
                  duration={1000}
                  offset={-96}
                  className="nav-links"
                  onClick={handleLinkClick}
                  tabIndex={0}
                >
                  About
                </ScrollLink>
              </li>
              <li className="nav-items">
                <a
                  href={Cv}
                  className="nav-links"
                  onClick={handleLinkClick}
                  download="Jayvin Carl Muring CV.pdf"
                >
                  Resume
                </a>
              </li>
            </ul>
            <ul className="nav-list">
              <li className="nav-items getit">
                <a
                  href={isMobile ? mobileGmailHref : gmailHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-links"
                  onClick={handleLinkClick}
                >
                  Get in touch
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
