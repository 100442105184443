import React from "react";
import PropTypes from "prop-types";
import styles from "./HighlightBlock.module.css";

const HighlightBlock = ({
    extraImages = [],
    extraImageAlts = []
}) => {
    return (
        <>
            <section className={`${styles.section} animsec`}>
                <div className={styles.container2}>
                    <div>
                        {extraImages.length > 0 &&
                            extraImages.map((imgSrc, index) => (
                                <img
                                    key={index}
                                    className={styles.images}
                                    src={imgSrc}
                                    alt={extraImageAlts[index] || `Extra Image ${index + 1}`}
                                    loading="lazy"
                                />
                            ))}
                    </div>
                </div>
            </section>
        </>
    );
};

HighlightBlock.propTypes = {
    extraImages: PropTypes.arrayOf(PropTypes.string),
    extraImageAlts: PropTypes.arrayOf(PropTypes.string),
};

export default HighlightBlock;
