import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import styles from "./Password.module.css";

// Assets
import Eye from "../../assets/img/Eye.svg";
import EyeOff from "../../assets/img/EyeOff.svg";
import Close from "../../assets/img/Close.svg";

const Password = ({ onSuccess }) => {
  const correctPassword = "Ui/Ux@Design";
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Toggle password visibility
  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisible((prevVisible) => !prevVisible);
  }, []);

  // Handle password input change
  const handlePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  // Validate password and scroll to top on success
  const handleValidation = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (password === correctPassword) {
          setErrorMessage("");
          onSuccess();
        } else {
          setErrorMessage("Incorrect password. Please try again");
        }
        scroll.scrollToTop({ behavior: "auto", passive: false });
        setPassword("");
      }
    },
    [password, onSuccess]
  );

  return (
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={() => navigate(-1)}>
        <img className={styles.closeIcon} src={Close} alt="Close" />
      </button>

      <div className={styles.innerContainer}>
        <div className={styles.content}>
          <h3 className={styles.title}>Password protected</h3>
          <p className={styles.description}>
            Enter the password to access this project
          </p>
          <div className={styles.inputWrapper}>
            <div className={styles.inputAndToggle}>
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                placeholder="Password"
                onChange={handlePassword}
                onKeyDown={handleValidation}
                className={`${styles.input} ${errorMessage ? styles.invalid : ""}`}
              />
              <button
                className={styles.toggleButton}
                onClick={togglePasswordVisibility}
              >
                <img
                  className={styles.eyeIcon}
                  src={passwordVisible ? EyeOff : Eye}
                  alt={passwordVisible ? "Hide password" : "Show password"}
                />
              </button>
            </div>
            {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
