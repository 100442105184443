import React from "react";

// Compnenents
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/Animations/SectionAnimation/SecAnimation";
import PageTransition from "../../Components/Animations/PageTransition/Transition";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import IntroductionBlock1 from "../../Components/Layouts/CaseStudyLayout/IntroductionBlock/IntroductionBlock1";
import ChipBlue from "../../Components/Chips/ChipBlue";
import TwoColBlock1 from "../../Components/Layouts/CaseStudyLayout/TwoColumnsBlock/TwoColBlock1";
import TwoColBlock2 from "../../Components/Layouts/CaseStudyLayout/TwoColumnsBlock/TwoColBlock2";
import HighlightBlock1 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock1";
import HighlightBlock2 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock2";
import HighlightBlock3 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock3";
import Card2 from "../../Components/Cards/Card2/Card2";
import Card3 from "../../Components/Cards/Card3/Card3";

// Assets
import CliqqCover from "../../assets/img/Cover.jpg"
import Cliqqimg1 from "../../assets/img/Cliqqimg1.png";
import Cliqqimg2 from "../../assets/img/Cliqqimg2.png";
import Cliqqimg3 from "../../assets/img/Cliqqimg3.png";
import Cliqqimg4 from "../../assets/img/Cliqqimg4.png";
import Cliqqimg5 from "../../assets/img/Cliqqimg5.png";
import Cliqqimg6 from "../../assets/img/Cliqqimg6.png";
import Cliqqimg7 from "../../assets/img/Cliqqimg7.png";
import Cliqqimg8 from "../../assets/img/Cliqqimg8.png";
import Icon1 from "../../assets/img/Icon1.png";
import Icon2 from "../../assets/img/Icon2.png";
import Icon3 from "../../assets/img/Icon3.png";
import Icon4 from "../../assets/img/Icon4.png";
import Iphone1 from "../../assets/img/Iphone1.png";
import Iphone2 from "../../assets/img/Iphone2.png";
import Iphone3 from "../../assets/img/Iphone3.png";
import Screen1 from "../../assets/img/Screen1.png";
import Screen2 from "../../assets/img/Screen2.png";
import Screen3 from "../../assets/img/Screen3.png";
import Screen4 from "../../assets/img/Screen4.png";
import Screen5 from "../../assets/img/Screen5.png";
import Screen6 from "../../assets/img/Screen6.png";
import Screen7 from "../../assets/img/Screen7.png";
import Screen8 from "../../assets/img/Screen8.png";
import Screen9 from "../../assets/img/Screen9.png";
import Screen10 from "../../assets/img/Screen10.png";
import Screen11 from "../../assets/img/Screen11.png";
import Screen12 from "../../assets/img/Screen12.png";
import Screen13 from "../../assets/img/Screen13.png";
import Screen14 from "../../assets/img/Screen14.png";
import Screen15 from "../../assets/img/Screen15.png";

function CliQQ() {
  return (
    <>
      <SecAnimation />
      <GraphBg1 />

      {/* ================ Hero section ================ */}
      <IntroductionBlock1
        cover={CliqqCover}
        title="CLiQQ Mobile App Redesign"
        description="CliQQ is a mobile app designed for 7-Eleven shoppers. It offers a digital loyalty program, convenient payment options, and exclusive deals. Earn points, enjoy rewards, and make your 7-Eleven visits more rewarding with CliQQ."
        role="UI/UX Designer, Illustrator"
        duration="3 Months"
        tools="Figma, Adobe Fresco, Notion, Adobe Illustrator, Procreate"
      >
        <ChipBlue text="Mobile app" />
      </IntroductionBlock1>

      {/* ================ Problem section ================ */}
      <TwoColBlock1
        imageSrc={Cliqqimg1}
        imageAlt="Illsutration"
        title="Problem"
        description={`The current CliQQ mobile app lacks intuitive navigation,
        hindering user engagement and satisfaction. A redesign is
        necessary to improve user experience, streamline flows, and
        promote active engagement through a visually appealing
        interface.`}
        hasLightbox={false}
      />

      {/* ================ Old design section ================ */}
      <HighlightBlock1
        isTextCenter={false}
        title2="Old design"
        description2={`Here is the original screens of the CliQQ mobile app before my
        redesign and highlight some of the key usability issues.
        <br />
        <br />
        Home Screen: Cluttered layout with unclear navigation. Confusing
        Navigation: Navigation icons and labels were unclear and
        unintuitive, making it difficult for users to find and access
        desired features efficiently.
        <br />
        <br />
        By examining these old screens, we can identify specific design
        elements and usability challenges that drove my decision to
        redesign the CliQQ app.`}
        image1={Cliqqimg2}
        hasLightbox={true}
      />

      {/* ================ Solution section ================ */}
      <TwoColBlock2
        imageSrc={Cliqqimg3}
        imageAlt="Illsutration"
        title="Solution"
        description={`The CliQQ mobile app redesign I incorporated character-based
        design, playful icon illustrations, gamification elements,
        streamlined navigation, and enhanced visual design. These
        changes made the app more engaging, friendly, and visually
        appealing, fostering active participation and a sense of
        enjoyment and reward for users.`}
        hasLightbox={false}
      />

      {/* ================ Research section ================ */}
      <TwoColBlock1
        imageSrc={Cliqqimg4}
        imageAlt="Illsutration"
        title="Research"
        description={`In redesigning the CliQQ mobile app, I analyzed app store
        feedback and conducted informal usability tests with friends to
        identify user needs and pain points. This guided the creation of
        a more user-centered design, with insights shaping key decisions
        to enhance user experience and effectiveness.`}
        hasLightbox={false}
      />

      {/* ================ Findings section ================ */}
      <HighlightBlock1
        isTextCenter={false}
        title1="Findings"
        description1={`My user research provided several key insights`}
      >
        <Card2
          title="Navigation challenges"
          description=" Users expressed difficulties in finding and accessing
          specific features within the app. Some reported confusion in
          navigating through different sections animsec, leading to
          frustration."
        />
        <Card2
          title="Loyalty program understanding"
          description=" Many users struggled to grasp the details of the loyalty
          program, such as earning points and redeeming rewards. This
          lack of clarity hindered their ability to fully benefit from
          the program."
        />
        <Card2
          title="Deals utilization"
          description=" Users faced obstacles in effectively taking advantage of
          deals offered within the app. Some mentioned difficulties in
          discovering these deals or redeeming them at the checkout."
        />
        <Card2
          title="Aesthetic design"
          description="Users voiced dissatisfaction with the aesthetic design of
          the current CliQQ mobile app, describing it as outdated and
          lacking visual appeal, which detracted from their overall
          experience and perception of the app."
        />
      </HighlightBlock1>

      {/* ================ Iconography section ================ */}
      <HighlightBlock1
        isTextCenter={false}
        title1="Iconography"
        description1={`In the Cliqq mobile app redesign, I conducted a significant
        overhaul of the iconography, prioritizing usability and clarity
        to ensure intuitive and recognizable user interactions. Thorough
        evaluation and redesign of existing icons aligned them with the
        updated visual style, emphasizing simplicity and visual
        hierarchy. The refined iconography enhanced user understanding
        and navigation, ultimately improving overall usability and
        creating a delightful user experience within the Cliqq mobile
        app.`}
        image1={Cliqqimg5}
        imageAlt1="Illustration"
        hasLightbox={true}
      />

      {/* ================ Typography section ================ */}
      <HighlightBlock1
        isTextCenter={false}
        title1="Typography"
        description1={`When working on the CliQQ mobile app redesign, the choice of
        typography held great significance in defining the app's visual
        identity and overall user experience. After thorough evaluation
        and consideration, I carefully selected "Nunito" as the primary
        typeface for the following key reasons:`}
        image1={Cliqqimg6}
        imageAlt1="Illustration"
        hasLightbox={false}
      >
        <Card3
          image={Icon1}
          imageAlt="Icon"
          title="Readability and legibility"
          description="Nunito's excellent legibility and clean design were
          paramount in creating a seamless reading experience for
          users. It was crucial for the app's content to be easily
          understood, ensuring smooth interactions throughout the
          app."
        />
        <Card3
          image={Icon2}
          imageAlt="Icon"
          title="Modern aesthetics"
          description="The contemporary and elegant look of Nunito perfectly
          aligned with my vision for the CliQQ app's modern and
          visually appealing interface. The rounded letterforms and
          harmonious proportions added a touch of sophistication,
          elevating the overall design."
        />
        <Card3
          image={Icon3}
          imageAlt="Icon"
          title="Versatility"
          description="Nunito's varied weights and styles provided me with the
          flexibility needed to establish clear hierarchies for
          different text elements. This versatility allowed me to
          distinguish headings, subheadings, and body text
          effectively, ensuring a well-organized content structure."
        />
        <Card3
          image={Icon4}
          imageAlt="Icon"
          title="User familiarity"
          description="Nunito's popularity in various digital interfaces,
          including apps and websites, made it an excellent choice.
          Utilizing a familiar typeface ensured a sense of comfort
          and ease for users, enhancing their overall navigation
          experience."
        />
      </HighlightBlock1>

      {/* ================ Characters section ================ */}
      <HighlightBlock1
        isTextCenter={false}
        title1="Characters"
        description1={`As the sole UI/UX designer for the Cliqq mobile app redesign, I
        crafted unique characters to maintain brand consistency, serving
        as brand ambassadors to enhance engagement, foster trust, and
        reinforce the brand identity throughout the app.`}
        image1={Cliqqimg7}
        imageAlt1="Illustration"
        description2={`Joe and Lala are the energetic and positive characters for the 7
        Eleven Cliqq mobile application. Joe, always in a vibrant red
        t-shirt, has contagious enthusiasm and a can-do attitude, making
        the Cliqq journey fun and rewarding. Lala, wearing an orange
        t-shirt, radiates positivity with her bright smile and cheerful
        demeanor. Together, they guide users through an exciting adventure
        of convenience and rewards, ensuring a delightful and memorable
        experience with 7 Eleven Cliqq.`}
        hasLightbox={false}
      />

      {/* ================ Redesigned screen section ================ */}
      <HighlightBlock1
        isTextCenter={true}
        title1="Redesigned screens"
        description1={`With a fresh look and easier navigation, every screen is
        redesigned for a better experience.`}
        hasLightbox={false}
      />

      {/* ================ A Personalized Shopping Experience section ================ */}
      <TwoColBlock2
        imageSrc={Iphone1}
        imageAlt="Iphone"
        title="A Personalized Shopping Experience"
        description={`Key functions like bill payments, buying load, and adding
        e-money are easily accessible in a grid layout. The intuitive
        design allows seamless navigation for features such as
        requesting money, loading wallets, and accessing Wi-Fi,
        enhancing user interaction and simplifying transactions.`}
        hasLightbox={true}
      />

      {/* ================ Rewards and Gamification section ================ */}
      <TwoColBlock1
        imageSrc={Iphone2}
        imageAlt="Iphone"
        title="Rewards and Gamificationce"
        description={`I introduced CliQQ Points with a gamified experience, featuring
        tiers like Bronze, Silver, Gold, and Platinum. As you earn
        points, you unlock exclusive rewards and benefits, supported by
        a visually refined interface for a modern and rewarding journey.`}
        hasLightbox={true}
      />

      {/* ================ Digital convenience section ================ */}
      <TwoColBlock2
        imageSrc={Iphone3}
        imageAlt="Iphone"
        title="Digital convenience"
        description={`I streamlined the Reward Cards feature, enabling real-time
              points tracking and seamless redemption. Users can now link
              their physical 7-Eleven reward card to the CliQQ app, gaining
              instant access to their points balance for easier and more
              rewarding shopping experiences.`}
        hasLightbox={true}
      />

      {/* ================ The screens section ================ */}
      <HighlightBlock1
        isTextCenter={true}
        title1="The screens"
        description1={`Each screen aims to provide a seamless and engaging user
        journey, ensuring a more intuitive and satisfying experience for
        CliQQ app users.`}
        hasLightbox={false}
      />

      <HighlightBlock2
        isTextCenter={true}
        extraImages={[
          Screen1,
          Screen2,
          Screen3,
          Screen4,
          Screen5,
          Screen6,
          Screen7,
          Screen8,
          Screen9,
          Screen10,
          Screen11,
          Screen12,
          Screen13,
          Screen14,
          Screen15
        ]}
        extraImageAlts={[
          "Screen1",
          "Screen2",
          "Screen3",
          "Screen4",
          "Screen5",
          "Screen6",
          "Screen7",
          "Screen8",
          "Screen9",
          "Screen10",
          "Screen11",
          "Screen12",
          "Screen13",
          "Screen14",
          "Screen15"
        ]}
      />

      {/* ================ Continuing the Journey section ================ */}
      <HighlightBlock3
        cover={Cliqqimg8}
        title="Continuing the Journey"
        description="As I wrap up redesigning the CliQQ mobile app's UI/UX, I'm
        eager to enhance user experience. Gathering feedback from
        friends has been invaluable, guiding improvements to ensure a
        seamless and engaging interface. Moving forward, I plan to
        continue refining the design based on user insights, focusing
        on usability and enhancing overall satisfaction. This project
        marks a milestone in my journey to innovate and elevate
        CliQQ's UI/UX, reinforcing my commitment to user-centered
        design and continuous improvement."
        hasLightbox={true}
      />

      {/* ================ Button to scroll up ================ */}
      <ScrollToTopButton />
    </>
  );
}

export default PageTransition(CliQQ);
