import React from "react";
import PageProgression from "../../pages/ProgressionPage/Progression";
import PageTransition from "../../Components/Animations/PageTransition/Transition";

function HealthHub() {
  return (
    <>
      <PageProgression />
    </>
  );
}

export default PageTransition(HealthHub);
