import React, { useState, useEffect } from "react";

// Compnenents
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/Animations/SectionAnimation/SecAnimation";
import usePasswordProtection from "../AuthPassword/PasswordHooks/usePasswordProtection";
import Password from "../../pages/AuthPassword/Password";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import IntroductionBlock2 from "../../Components/Layouts/CaseStudyLayout/IntroductionBlock/IntroductionBlock2";
import ChipBlue from "../../Components/Chips/ChipBlue";
import PageTransition from "../../Components/Animations/PageTransition/Transition";
import HighlightBlock1 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock1";
import TwoColBlock1 from "../../Components/Layouts/CaseStudyLayout/TwoColumnsBlock/TwoColBlock1";
import TwoColBlock2 from "../../Components/Layouts/CaseStudyLayout/TwoColumnsBlock/TwoColBlock2";

// Assets
import TawktoCover from "../../assets/img/TawktoCover.png"
import TawktoMobile1 from "../../assets/img/TawktoMobile1.png";
import TawktoMobile2 from "../../assets/img/TawktoMobile2.png";
import TawktoMobile3 from "../../assets/img/TawktoMobile3.png";
import TawktoMobile4 from "../../assets/img/TawktoMobile4.png";
import TawktoMobile5 from "../../assets/img/TawktoMobile5.png";
import TawktoMobile6 from "../../assets/img/TawktoMobile6.png";
import TawktoMobile7 from "../../assets/img/TawktoMobile7.png";
import TawktoMobile8 from "../../assets/img/TawktoMobile8.png";
import TawktoMobile9 from "../../assets/img/TawktoMobile9.png";
import TawktoMobile10 from "../../assets/img/TawktoMobile10.png";
import TawktoMobile11 from "../../assets/img/TawktoMobile11.png";
import TawktoMobile12 from "../../assets/img/TawktoMobile12.png";
import TawktoMobile13 from "../../assets/img/TawktoMobile13.png";
import TawktoMobile14 from "../../assets/img/TawktoMobile14.png";
import TawktoMobile15 from "../../assets/img/TawktoMobile15.png";
import TawktoMobile16 from "../../assets/img/TawktoMobile16.png";
import TawktoMobile17 from "../../assets/img/TawktoMobile17.png";
import TawktoMobile18 from "../../assets/img/TawktoMobile18.png";
import TawktoMobile19 from "../../assets/img/TawktoMobile19.png";
import TawktoMobile20 from "../../assets/img/TawktoMobile20.png";
import TawktoMobile21 from "../../assets/img/TawktoMobile21.png";
import TawktoMobile22 from "../../assets/img/TawktoMobile22.png";

// Define all the image arrays
const TawktoMobileImgs1 = [
  TawktoMobile8,
  TawktoMobile9,
  TawktoMobile10,
  TawktoMobile11,
  TawktoMobile12,
  TawktoMobile13,
  TawktoMobile14,
  TawktoMobile15,
  TawktoMobile16,
];

const TawktoMobileImgs2 = [
  TawktoMobile1,
  TawktoMobile2,
  TawktoMobile3,
  TawktoMobile4,
  TawktoMobile5,
  TawktoMobile6,
  TawktoMobile7,
];

const TawktoMobileImgs3 = [
  TawktoMobile17,
  TawktoMobile18,
  TawktoMobile19,
  TawktoMobile20,
  TawktoMobile21,
  TawktoMobile22,
];

function Tawkto() {
  const { isPasswordProtected, handleSuccess } = usePasswordProtection();

  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex1(
        (prevIndex) => (prevIndex + 1) % TawktoMobileImgs1.length
      );
      setCurrentIndex2(
        (prevIndex) => (prevIndex + 1) % TawktoMobileImgs2.length
      );
      setCurrentIndex3(
        (prevIndex) => (prevIndex + 1) % TawktoMobileImgs3.length
      );
    }, 2000); // Auto slide every 600ms

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <>
      {isPasswordProtected ? (
        <Password onSuccess={handleSuccess} />
      ) : (
        <>
          <SecAnimation />
          <GraphBg1 />

          {/*  ================ Hero section ================ */}
          <IntroductionBlock2
            cover={TawktoCover}
            title=" Designing and Implementing Key Features for Tawk.to’s Mobile
            Platform"
            description="Tawk.to, a leading live chat platform, required a
            comprehensive redesign and the addition of new features to
            enhance its mobile application. The existing mobile platform
            lacked consistency across Android and iOS versions, making
            it challenging for users to have a seamless experience. To
            address these issues, a decision was made to introduce key
            new features and improve the design system, ensuring a more
            cohesive and user-friendly platform."
            role="UI/UX Designer (Android)"
            duration="1 year and 7 months"
            team="Lead UI/UX Designer, Graphic Designer, UI/UX Designer
            (iOS), UI/UX Designer (Web)"
            tools="Figma, Notion, Adobe Illustrator,"
          >
            <ChipBlue text="Mobile app" />
          </IntroductionBlock2>

          {/* ================ Project Goal section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Project Goal"
            description1={`The goal of this project was to redesign and enhance Tawk.to’s
            mobile platform to ensure a seamless user experience across
            Android and iOS devices. The primary objective was to improve
            the usability of key features, such as “Contacts”, “View
            Direct Message”, and “Inbox” modules etc, while ensuring
            design consistency across both platforms. Additionally, the
            introduction of a new design system aimed to streamline
            development and ensure future scalability of the platform.`}
            hasLightbox={false}
          />

          {/* ================ My Rolse section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="My Role"
            description1={`As a UI/UX designer, I was primarily responsible for the
            design and implementation of key features on the Android
            version of Tawk.to’s mobile platform. I focused on improving
            the user interface and experience across several major
            modules, ensuring consistency and usability. Additionally, I
            collaborated with the team and assisted with the iOS version
            when needed, contributing to a unified experience across both
            platforms.`}
            hasLightbox={false}
          />

          {/* ================ Problem Statement section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Problem Statement"
            description1={`Tawk.to’s mobile platform was facing challenges related to
            inconsistent UI/UX design across Android, iOS and Web, making
            it difficult for users to transition smoothly between the
            three. Additionally, the existing modules, such as “Contacts”,
            “View Direct Message”, and “Inbox”, were outdated and needed
            redesigns to improve usability, accessibility, and
            performance. These inconsistencies and usability issues were
            causing friction for users, ultimately affecting engagement
            and satisfaction.`}
            hasLightbox={false}
          />

          {/* ================ Solution Statement section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Solution Statement"
            description1={` To resolve inconsistencies and improve usability across
            Tawk.to’s mobile platform, I redesigned key modules including
            Contacts, View Direct Message, and Inbox etc... to create a
            more seamless and efficient workflow. By simplifying
            navigation, optimizing task flows, and implementing a unified
            design system, I ensured a smoother transition between Android
            and iOS. These enhancements reduced friction, improved
            accessibility, and led to a more intuitive user experience,
            ultimately increasing engagement and reducing task completion
            time.`}
            hasLightbox={false}
          />

          {/* ================ Instant Customer Support section ================ */}
          <TwoColBlock2
            imageSrc={TawktoMobileImgs1[currentIndex1]}
            imageAlt={`Slide ${currentIndex1 + 1}`}
            title="Instant Customer Support"
            description={`Here’s a glimpse of the app we worked on, the Chat module
            enables users to communicate with businesses in real time. It
            supports text, images, and file sharing, with conversation
            history saved for future reference. Notifications ensure no
            message goes unanswered.`}
            hasLightbox={false}
          />

          {/* ================ Managing Contacts Made Simple section ================ */}
          <TwoColBlock1
            imageSrc={TawktoMobileImgs2[currentIndex2]}
            imageAlt={`Slide ${currentIndex2 + 1}`}
            title="Managing Contacts Made Simple"
            description={`Here’s another glimpse of the app: The Contact module in
            Tawk.to enables users to add and manage people in their
            contact list. Users can save client or business contact
            details with ease, ensuring important connections are always
            accessible. This feature streamlines organization for
            efficient communication.`}
            hasLightbox={false}
          />

          {/* ================ Simplifying Ticket Management section ================ */}
          <TwoColBlock2
            imageSrc={TawktoMobileImgs3[currentIndex3]}
            imageAlt={`Slide ${currentIndex3 + 1}`}
            title="Simplifying Ticket Management"
            description={`The Ticket module in Tawk.to allows users to seamlessly add
            and track support tickets in one place. From creating detailed
            tickets with priorities and attachments to viewing and
            managing them efficiently, this feature ensures smooth
            communication and faster issue resolution, keeping teams
            organized and responsive.`}
            hasLightbox={false}
          />

          {/* ================ Impact and Results section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Impact and Results"
            description1={`While refining Tawk.to’s mobile platform, I focused on
            optimizing key modules and user workflows. As a result, we saw
            a 15% increase in user engagement and reduced task completion
            time by 20%. These improvements not only streamlined
            interactions but also ensured a more intuitive experience,
            setting the stage for ongoing updates and enhancing navigation
            across Android and iOS.`}
            hasLightbox={false}
          />

          {/* ================ Button to scroll up ================ */}
          <ScrollToTopButton />
        </>
      )}
    </>
  );
}

export default PageTransition(Tawkto);
