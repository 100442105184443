import React from "react";
import PropTypes from "prop-types";
import styles from "./HighlightBlock.module.css";

const HighlightBlock4 = ({
  isTextCenter,
  title,
  description
}) => {
  return (
    <>
      <section className={`${styles.section} animsec`}>
        <div className={styles.container}>
          {(title || description) && (
            <div
              className={`animatedcont`}
            >
              <h5 className={`${styles.title} ${isTextCenter ? styles.textCenter : ""}`}>{title}</h5>
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

HighlightBlock4.propTypes = {
  isTextCenter: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default HighlightBlock4;
