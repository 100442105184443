import React from "react";
import PropTypes from "prop-types";
import styles from "./Card3.module.css";

const Card3 = ({ image, imageAlt, title, description }) => {
    return (
        <div className={styles.card}>
            <div className={`${styles.cardContent} animatedcont`}>
                <div>
                    <img className={styles.icon} src={image} alt={imageAlt} loading="lazy" />
                </div>
                <div>
                    <p className={styles.cardTitle}>{title}</p>
                    <p className={styles.cardDescription}>{description}</p>
                </div>
            </div>
        </div>
    );
};

Card3.propTypes = {
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default Card3;
