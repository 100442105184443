import React from "react";
import PropTypes from "prop-types";
import styles from "./Chips.module.css";

const ChipGreen = ({ text }) => {
  return (
    <div className={styles.chipGreenContainerStyle}>
      <p className={styles.chipGreenTextStyle}>{text}</p>
    </div>
  );
};

ChipGreen.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ChipGreen;
