import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ReactLenis } from "lenis/react";
import React, { Suspense, lazy } from "react";
import { AnimatePresence } from "framer-motion";

// Components
import CursorAnimation from "./Components/Animations/CursorAnimation/CursorAnimation";
import Footer from "./Components/Footer/Footer";
import Loading from "./Components/Animations/Loader/Loading";
import Navbar from "./Components/Navbar/Navbar";
import NetStatus from "./pages/NetworkStatus/NetworkStatus";
import ErrorBoundary from "./pages/ErrorBoundary/ErrorBoundary";
import Password from "./pages/AuthPassword/Password";

// Pages
import CliQQ from "./pages/CaseStudy/CliQQ";
import HealthHub from "./pages/CaseStudy/HealthHub";
import Netto from "./pages/CaseStudy/Netto";
import ConfidentialWeb1 from "./pages/CaseStudy/Maxicare1";
import ConfidentialWeb2 from "./pages/CaseStudy/Maxicare2";
import ConfidentialWeb3 from "./pages/CaseStudy/Tawkto";
const Home = lazy(() => import("./pages/Home/Home"));
const NotFound = lazy(() => import("./pages/ProgressionPage/NotFound"));

function App() {
  return (
    <div className="wrapper" id="wrapper">
      <NetStatus>
        <Router>
          <ReactLenis
            root
            options={{ orientation: "both", easing: "easeOutCirc" }}
          >
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Navbar />
                <AppRoutes />
                <Footer />
              </Suspense>
            </ErrorBoundary>
          </ReactLenis>
        </Router>
      </NetStatus>
    </div>
  );
}

function AppRoutes() {
  const location = useLocation(); // Get the current location to use as key for routing

  return (
    <>
      <CursorAnimation />
      <AnimatePresence mode="wait">
        <main>
          <Routes location={location} key={location.pathname}>
            <Route path="/" index element={<Home />} />
            <Route path="/CliQQ" element={<CliQQ />} />
            <Route path="/HealthHub" element={<HealthHub />} />
            <Route path="/Netto" element={<Netto />} />
            <Route path="/ConfidentialWeb1" element={<ConfidentialWeb1 />} />
            <Route path="/ConfidentialWeb2" element={<ConfidentialWeb2 />} />
            <Route path="/ConfidentialWeb3" element={<ConfidentialWeb3 />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/Password" element={<Password />} />
          </Routes>
        </main>
      </AnimatePresence>
    </>
  );
}

export default App;