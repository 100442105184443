import { animateScroll as scroll } from "react-scroll";
import React, { useState, useEffect, useCallback } from "react";
import Lottie from "lottie-react";

// Styles sheet
import "../Loader/Loading.css";

// Assets
import LoadingAni from "./../../../assets/img/Loading.json";

function Loading() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadbg, setLoadBg] = useState(true);

  const removingLoadBg = useCallback(() => {
    setLoadBg(false);
  }, []);

  useEffect(() => {
    scroll.scrollToTop({ behavior: "smooth", passive: true });

    const transitionTimeout = setTimeout(() => {
      setIsLoading(false);
      setTimeout(removingLoadBg, 3200);
    }, 3200);

    return () => {
      clearTimeout(transitionTimeout);
    };
  }, [removingLoadBg]);

  return (
    <>
      {loadbg && (
        <div className={`load-bg ${isLoading ? "showLoading" : "hideLoading"}`}>
          <Lottie
            animationData={LoadingAni}
            loop={true}
            width={50}
            height={50}
            className="loading-lottie"
          />
          <p className="t-lg-bold">Loading...</p>
        </div>
      )}
    </>
  );
}

export default Loading;
