import React from "react";
import PropTypes from "prop-types";
import styles from "./Card2.module.css";

const Card2 = ({ image, imageAlt, title, description }) => {
  return (
    <div className={styles.card}>
      <div className={`${styles.cardContent} animatedcont`}>
        <div>
          {image && (
          <img className={styles.icon} src={image} alt={imageAlt} loading="lazy" />
          )}
        </div>
        <div>
          <p className={styles.cardTitle}>{title}</p>
          <p className={styles.cardDescription}>{description}</p>
        </div>
      </div>
    </div>
  );
};

Card2.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card2;
