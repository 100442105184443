import { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

const usePasswordProtection = () => {
  const [isPasswordProtected, setIsPasswordProtected] = useState(true);

  const handleSuccess = () => {
    setIsPasswordProtected(false);
  };

  useEffect(() => {
    if (isPasswordProtected) {
      document.body.style.overflow = "hidden";
    } else {
      scroll.scrollToTop({ behavior: "none", passive: false });
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isPasswordProtected]);

  return { isPasswordProtected, handleSuccess };
};

export default usePasswordProtection;
