import React from "react";

// Compnenents
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/Animations/SectionAnimation/SecAnimation";
import usePasswordProtection from "../AuthPassword/PasswordHooks/usePasswordProtection";
import Password from "../../pages/AuthPassword/Password";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import ChipRed from "../../Components/Chips/ChipRed";
import IntroductionBlock1 from "../../Components/Layouts/CaseStudyLayout/IntroductionBlock/IntroductionBlock1";
import TwoColBlock1 from "../../Components/Layouts/CaseStudyLayout/TwoColumnsBlock/TwoColBlock1";
import TwoColBlock2 from "../../Components/Layouts/CaseStudyLayout/TwoColumnsBlock/TwoColBlock2";
import HighlightBlock1 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock1";
import HighlightBlock5 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock5";
import PageTransition from "../../Components/Animations/PageTransition/Transition";

// Assets
import MaxicareCover from "../../assets/img/Maxicare.png";
import maxicareIllustration1 from "../../assets/img/MaxicareIllustration1.png";
import maxicareIllustration2 from "../../assets/img/MaxicareIllustration2.png";
import maxicareIllustration3 from "../../assets/img/MaxicareIllustration3.png";
import maxicareIllustration4 from "../../assets/img/MaxicareIllustration4.png";

function Maxicare1() {
  const { isPasswordProtected, handleSuccess } = usePasswordProtection();

  return (
    <>
      {isPasswordProtected ? (
        <Password onSuccess={handleSuccess} />
      ) : (
        <>
          <SecAnimation />
          <GraphBg1 />

          {/* ================ Hero section ================ */}
          <IntroductionBlock1
            cover={MaxicareCover}
            title="Designing Maxicare’s Mascot and Illustrations for a Web
            Application"
            description="Maxicare, a leading healthcare provider, needed a new
            Members Portal to improve user experience and expand the
            platform’s functionality. The existing portal was outdated
            and difficult to use, leading to frustration among users. As
            a result, the decision was made to create an entirely new
            portal with enhanced usability and additional features
            tailored to meet evolving user needs."
            role="UI/UX Designer, Illustrator"
            duration="9 Months"
            tools="Figma, Adobe Illustrator, Procreate"
          >
            <ChipRed text="Illustrations" />
          </IntroductionBlock1>

          {/* ================ Initial Design Concept section ================ */}
          <TwoColBlock1
            imageSrc={maxicareIllustration1}
            imageAlt="Illsutration"
            title="Initial Design Concept"
            description={` I started with a rough sketch of the bear mascot, basing it on
            the current style of their existing bear but adding my own
            twist. This version aimed to enhance the overall design while
            keeping it familiar, serving as the foundation for further
            refinement.`}
            hasLightbox={true}
          />

          {/* ================ My Approach section ================ */}
          <TwoColBlock2
            imageSrc={maxicareIllustration2}
            imageAlt="Illsutration"
            title="My Approach"
            description={`I developed a new version of the bear mascot that had a more
            polished and adaptable style. The redesign focused on aligning
            with Maxicare’s overall branding, ensuring consistency in
            poses and expressions across different user interface
            elements. The new mascot was designed to be more versatile,
            appearing in various scenarios throughout the web application.`}
            hasLightbox={true}
          />

          <HighlightBlock1
            isTextCenter={false}
            description1={`In this updated version, I dressed the bear as a doctor,
            replacing the casual T-shirt with a doctor’s coat to better
            reflect Maxicare’s healthcare focus and give the character a
            more professional, relevant appearance.`}
            hasLightbox={false}
          />

          {/* ================ The Presentation section ================ */}
          <HighlightBlock1
            isTextCenter={false}
            image1={maxicareIllustration3}
            imageAlt1="The Presentation"
            title2={`The Presentation`}
            description2={`When I presented the new mascot design to the stakeholders, I
            explained how the updated bear would bring consistency and
            versatility to their brand. However, they were strongly
            attached to their original mascot and were firm about not
            replacing it. Despite the improvements in design, they
            preferred to stick with the familiar bear character and
            decided not to incorporate the new bear into the web
            application we were building.`}
            hasLightbox={true}
          />

          {/* ================ The Outcome section ================ */}
          <HighlightBlock5
            isTextCenter={true}
            title1="The Outcome"
            description1={`After further discussions, we agreed to use a more generic
            illustration style instead of the bear mascot. This approach
            maintained visual consistency without altering the
            application’s design.`}
            imageSrc={maxicareIllustration4}
            imageAlt="Illustration"
            description2={`Here are some of the generic illustrations I developed for the
            application. These designs were crafted to maintain visual
            consistency and enhance the user experience while adhering to
            the client’s preferences.`}
            hasLightbox={true}
          />

          {/* ================ Key Takeaways and Conclusions section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Key Takeaways and Conclusions"
            description1={`This project highlighted the importance of adaptability in
            design. Although the new bear mascot wasn’t used, I quickly
            adapted to deliver a solution that met the stakeholders’
            needs. The time spent on this process improved my skills in
            managing client expectations and maintaining design integrity.
            Even though the web app is not yet deployed in production,
            using generic illustrations resolved concerns and aligned with
            the project’s goals, contributing to a successful outcome.`}
            hasLightbox={false}
          />

          {/* ================ Button to scroll up ================ */}
          <ScrollToTopButton />
        </>
      )}
    </>
  );
}

export default PageTransition(Maxicare1);
