import React from "react";
import styles from "./LightBox.module.css"; // Import CSS Module

// Assets
import Close from "../../assets/img/Close.svg";

const LightBox = React.memo(({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay} onClick={onClose}>
      <button className={styles.closeButton} onClick={onClose}>
        <img src={Close} alt="Close" />
      </button>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <img src={image} alt="Illustration" />
      </div>
    </div>
  );
});

export default LightBox;
