import React, { useState, useCallback, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import styles from "./ScrollToTopButton.module.css"; // Import CSS module

// Assets
import ScrollArrow from "../../assets/img/ScrollArrow.svg";

const ScrollToTopButton = () => {
  const [isHideBtn, setHideBtn] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  // Function for scroll to top button.
  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      setHideBtn(false);
    } else {
      setHideBtn(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  // Auto-hide logic
  useEffect(() => {
    const handleAutoHide = () => setHideBtn(true);

    let timeoutId = setTimeout(handleAutoHide, 2000);

    const onScroll = () => {
      clearTimeout(timeoutId);
      handleScroll();
      timeoutId = setTimeout(handleAutoHide, 2000);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("scroll", onScroll);
    };
  }, [handleScroll]);

  return (
    <button
      className={`${styles.btn} ${isHideBtn ? styles.hideBtn : styles.showBtn}`}
      onClick={() => {
        scroll.scrollToTop({ behavior: "smooth", passive: true });
      }}
    >
      <img src={ScrollArrow} alt="Arrow" />
    </button>
  );
};

export default ScrollToTopButton;
