import React from "react";

// Compnenents
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/Animations/SectionAnimation/SecAnimation";
import usePasswordProtection from "../AuthPassword/PasswordHooks/usePasswordProtection";
import Password from "../../pages/AuthPassword/Password";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import PageTransition from "../../Components/Animations/PageTransition/Transition";
import IntroductionBlock2 from "../../Components/Layouts/CaseStudyLayout/IntroductionBlock/IntroductionBlock2";
import ChipGreen from "../../Components/Chips/ChipGreen";
import HighlightBlock1 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock1";
import HighlightBlock4 from "../../Components/Layouts/CaseStudyLayout/HighlightBlock/HighlightBlock4";

// Assets
import maxicareCover from "../../assets/img/Maxicare.png";
import maxicareIllustration5 from "../../assets/img/MaxicareIllustration5.png";
import maxicareIllustration6 from "../../assets/img/MaxicareIllustration6.png";
import maxicareIllustration7 from "../../assets/img/MaxicareIllustration7.png";
import maxicareIllustration8 from "../../assets/img/MaxicareIllustration8.gif";
import maxicareIllustration9 from "../../assets/img/MaxicareIllustration9.gif";

function Maxicare2() {
  const { isPasswordProtected, handleSuccess } = usePasswordProtection();

  return (
    <>
      {isPasswordProtected ? (
        <Password onSuccess={handleSuccess} />
      ) : (
        <>
          <SecAnimation />
          <GraphBg1 />

          {/*  ================ Hero section ================ */}
          <IntroductionBlock2
            cover={maxicareCover}
            title="Designing the New Maxicare Members Portal Platform"
            description="Maxicare, a leading healthcare provider, needed a new
            Members Portal to improve user experience and expand the
            platform’s functionality. The existing portal was outdated
            and difficult to use, leading to frustration among users. As
            a result, the decision was made to create an entirely new
            portal with enhanced usability and additional features
            tailored to meet evolving user needs."
            role="UI/UX Designer, Illustrator"
            duration="9 Months"
            team="Lead UI/UX Designer, Junior UI/UX Designer, Project
            Manager, CX Designer"
            tools="Figma, Adobe Fresco, Notion, Adobe Illustrator,
            Procreate"
          >
            <ChipGreen text="Web progressive app" />
          </IntroductionBlock2>

          {/* ================ Project Goal section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Project Goal"
            description1={`The primary goal was to build a new Members Portal from the
            ground up, featuring a modern user interface and improved user
            experience. Additionally, the portal would include new
            features that were not present in the old version, offering
            users more comprehensive tools to manage their healthcare
            plans, access services, and interact with the Maxicare
            ecosystem more effectively.`}
            hasLightbox={false}
          />

          {/* ================ My Rolse section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="My Role"
            description1={`As the UI/UX Designer, I was responsible for creating both
            low-fidelity and high-fidelity designs, illustrations, and the
            design system. I collaborated closely with the Customer
            Experience Designer, who led the research and user journey
            mapping, and was also part of the team working on affinity
            mapping. My focus was on the visual design and ensuring that
            the portal’s UI aligned with the new and expanded
            functionalities.`}
            hasLightbox={false}
          />

          {/* ================ Problem Statement section ================ */}
          <HighlightBlock4
            isTextCenter={true}
            title="Problem Statement"
            description={` The original Members Portal had significant usability issues
            and lacked many features that users needed:
            <br />
            <br />
            • Poor Usability: Users struggled to navigate the existing
            portal, making it difficult to access important features.
            <br />
            <br />
            • Limited Functionality: The old portal offered only basic
            features, which did not fully meet the needs of Maxicare
            members.
            <br />
            <br />
            • Outdated Design: The design was not aligned with modern user
            expectations, leading to a subpar user experience.
            <br />
            <br />
            Given these issues, we aimed not only to redesign the portal
            but also to expand its capabilities with new features and a
            user-centered approach.`}
          />

          {/* ================ Research & Discovery section ================ */}
          <HighlightBlock1
            isTextCenter={false}
            title1="Research & Discovery"
            description1={`The Customer Experience Designer led extensive user research
            to understand the needs, pain points, and goals of Maxicare’s
            users. I participated in affinity mapping sessions where we
            synthesized this research, categorizing user insights to
            inform the design process.`}
            image1={maxicareIllustration5}
            imageAlt1="Illustration"
            description2={`Key Findings:
            <br />
            Users preferred simple search by city or landmark, seamless
            booking with real-time availability, and clear service
            selection. They valued distance indicators and booking
            confirmations via email or text without needing an account.`}
            hasLightbox={true}
          />

          {/* ================ Low-Fidelity Wireframes section ================ */}
          <HighlightBlock1
            isTextCenter={false}
            title1="Low-Fidelity Wireframes"
            description1={`Starting with low-fidelity wireframes, We focused on
            establishing a new structure that incorporated the additional
            features identified in the research phase. We aimed to
            streamline user flows while also ensuring that new
            functionalities were easily accessible.`}
            image1={maxicareIllustration6}
            imageAlt1="Illustration"
            hasLightbox={true}
          />

          {/* ================ High-Fidelity Designs section ================ */}
          <HighlightBlock1
            isTextCenter={false}
            title1="High-Fidelity Designs"
            description1={`After wireframes were approved, We moved to high-fidelity
            designs. These designs introduced a fresh, modern look that
            aligned with Maxicare’s branding but also elevated the user
            experience with new visual and interactive elements.`}
            image1={maxicareIllustration7}
            imageAlt1="Illustration"
            hasLightbox={true}
          />

          {/* ================ Book a Consultation section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Book a Consultation"
            description1={`The Book a Consultation feature was redesigned to simplify the
            process. Users select their policy, find the nearest clinic,
            choose a date and time, select a doctor, and confirm the
            appointment. Notifications are sent via email and mobile, and
            upcoming appointments are easily trackable on the dashboard.`}
            hasLightbox={false}
          />

          <HighlightBlock1
            isTextCenter={false}
            image1={maxicareIllustration8}
            hasLightbox={true}
          />

          {/* ================ Request a Medical Test section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Request a Medical Test"
            description1={`For Medical Test requests, users upload a diagnostic order,
            choose procedures, select a clinic and time, and confirm via
            OTP. Appointments appear on the dashboard with notifications
            for updates.`}
            hasLightbox={false}
          />

          <HighlightBlock1
            isTextCenter={false}
            image1={maxicareIllustration9}
            hasLightbox={true}
          />

          {/* ================ Handoff & Current Status section ================ */}
          <HighlightBlock1
            isTextCenter={true}
            title1="Handoff & Current Status"
            description1={`After finalizing the design, the project was successfully
            handed off to the development team, who are currently building
            the Progressive Web Application based on the approved UI/UX
            designs. Comprehensive documentation, prototypes, and assets
            were provided to ensure a smooth transition and alignment
            between design and development. We continue to collaborate
            with the developers to address any design clarifications and
            ensure the final product adheres to the vision laid out during
            the design phase.`}
            hasLightbox={false}
          />

          {/* ================ Button to scroll up ================ */}
          <ScrollToTopButton />
        </>
      )}
    </>
  );
}

export default PageTransition(Maxicare2);
