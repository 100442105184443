import React from "react";
import PropTypes from "prop-types";
import styles from "./TwoColBlock.module.css"; // Import CSS module

import LightBox from "../../../LightBox/LightBox";
import useLightBox from "../../../LightBox/LightBoxHooks/useLightbox";

const TwoColBlock1 = ({
  additionalClass1,
  additionalClass2,
  additionalClass3,
  additionalClass4,
  imageSrc,
  imageAlt,
  title,
  description,
  child,
  hasLightbox,
}) => {
  const { isOpen, image, openLightBox, closeLightBox } = useLightBox(); // ✅ Corrected

  return (
    <>
      <section className={`${styles.section} animsec ${additionalClass1}`}>
        <div className={`${styles.container} animatedcont ${additionalClass2}`}>
          <div className={`${styles.content} ${additionalClass4}`}>
            <h5 className={`${styles.title}`}>{title}</h5>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
          <div className={`${styles.content} ${additionalClass3}`}>
            {child || (
              <img
                className={`${styles.image} ${hasLightbox ? "lb-img" : ""}`}
                src={imageSrc}
                alt={imageAlt}
                loading="lazy"
                onClick={() => {
                  if (hasLightbox) {
                    openLightBox(imageSrc);
                  }
                }}
              />
            )}
          </div>
        </div>
      </section>

      {/* LightBox for thumbnail */}
      <LightBox isOpen={isOpen} image={image} onClose={closeLightBox} />
    </>
  );
};

TwoColBlock1.propTypes = {
  additionalClass1: PropTypes.string,
  additionalClass2: PropTypes.string,
  additionalClass3: PropTypes.string,
  additionalClass4: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  child: PropTypes.node,
  hasLightbox: PropTypes.bool,
};

export default TwoColBlock1;
