import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const Cursor = styled.div`
  position: fixed;
  top: -1rem;
  left: -0.5rem;
  width: 16px;
  height: 16px;
  background-color: var(--clr-primary-6);
  border-radius: 50%;
  pointer-events: none;
  z-index: 1200;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-base-1);
  font-size: 3px;

  @media (max-width: 1280px) {
    display: none;
  }
`;

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const textRef = useRef(null);
  const location = useLocation();
  const observer = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    const text = textRef.current;

    if (!cursor) return;

    const moveCursor = (e) => {
      gsap.to(cursor, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.8,
        ease: "power3.out",
      });
    };

    const handleMouseEnter = (e) => {
      gsap.to(cursor, {
        scale: 8,
        backgroundColor: "var(--clr-primary-6)",
      });
      if (text) {
        text.textContent = e.target.classList.contains("vp-cards") ? "Explore" : "View";
        text.style.display = "block";
      }
    };

    const handleMouseLeave = () => {
      gsap.to(cursor, {
        scale: 1,
        backgroundColor: "var(--clr-primary-6)",
      });
      if (text) text.style.display = "none";
    };

    // Attach global mousemove event
    document.addEventListener("mousemove", moveCursor);

    // Function to observe new elements and attach listeners
    const observeElements = () => {
      const elements = document.querySelectorAll(".vp-cards, .lb-img");
      elements.forEach((el) => {
        el.addEventListener("mouseenter", handleMouseEnter);
        el.addEventListener("mouseleave", handleMouseLeave);
      });
    };

    // Use MutationObserver to detect new elements on route change
    observer.current = new MutationObserver(() => observeElements());
    observer.current.observe(document.body, { childList: true, subtree: true });

    // Run initially in case elements are already present
    observeElements();

    return () => {
      document.removeEventListener("mousemove", moveCursor);

      if (observer.current) observer.current.disconnect();

      document.querySelectorAll(".vp-cards, .lb-img").forEach((el) => {
        el.removeEventListener("mouseenter", handleMouseEnter);
        el.removeEventListener("mouseleave", handleMouseLeave);
      });

      // Reset cursor state
      gsap.to(cursor, { scale: 1, backgroundColor: "var(--clr-primary-6)" });
      if (text) text.style.display = "none";
    };
  }, [location.pathname]); // Runs on route change

  return (
    <Cursor ref={cursorRef}>
      <span ref={textRef} style={{ display: "none" }}>Explore</span>
    </Cursor>
  );
};

export default CustomCursor;
