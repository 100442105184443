import React from "react";
import PropTypes from "prop-types";
import styles from "./IntroductionBlock.module.css"; // Import CSS module

const IntroductionBlock = ({
  cover,
  title,
  description,
  children,
  child,
  role,
  duration,
  team,
  tools,
}) => {
  return (
    <section className={`${styles.section} animsec`}>
      <div className={styles.container}>
        <div
          className={styles.cover}
          style={{ backgroundImage: `url(${cover})` }}
        ></div>
        <div className={styles.innerContainer}>
          <div className="animatedcont">
            <h5 className={styles.title}>{title}</h5>
            {/* For chip */}
            {children}
            <p className={`${styles.descriptionPadding} ${styles.description}`}>{description}</p>
          </div>

          <div>
            <div className={styles.columnContainer}>
              <div className={`${styles.column} animatedcont`}>
                <p className={styles.description1}>Role</p>
                <p className={styles.description2}>{role}</p>
              </div>
              <div className={`${styles.column} animatedcont`}>
                <p className={styles.description1}>Duration</p>
                <p className={styles.description2}>{duration}</p>
              </div>
              {child && (
                <div className={`${styles.column} animatedcont`}>
                  <p className={styles.description1}>Team</p>
                  <p className={styles.description2}>{team}</p>
                </div>
              )}
              <div className={`${styles.column} animatedcont`}>
                <p className={styles.description1}>Tools</p>
                <p className={styles.description2}>{tools}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

IntroductionBlock.propTypes = {
  cover: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  child: PropTypes.node,
  role: PropTypes.string,
  duration: PropTypes.string,
  team: PropTypes.string,
  tools: PropTypes.string
};

export default IntroductionBlock;
