import React from "react";
import PropTypes from "prop-types";
import styles from "./HighlightBlock.module.css";

import LightBox from "../../../LightBox/LightBox";
import useLightBox from "../../../LightBox/LightBoxHooks/useLightbox";

const HighlightBlock3 = ({
    cover,
    title,
    description,
    hasLighbox
}) => {
    const { isOpen, image, openLightBox, closeLightBox } = useLightBox();

    return (
        <>
            <section className={`${styles.section} animsec`}>
                <div
                    className={`${styles.cover} ${hasLighbox ? "lb-img" : ""}`}
                    style={{ backgroundImage: `url(${cover})` }}
                    onClick={() => openLightBox(cover)}
                ></div>

                <div className={styles.container3}>
                    <div className="animatedcont">
                        <h5 className={styles.title}>{title}</h5>
                        <p className={styles.description}>{description}</p>
                    </div>
                </div>
            </section>

            {/* LightBox for thumbnail */}
            <LightBox isOpen={isOpen} image={image} onClose={closeLightBox} />
        </>
    );
};

HighlightBlock3.propTypes = {
    cover: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    hasLighbox: PropTypes.bool
};

export default HighlightBlock3;
