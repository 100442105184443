import React from "react";
import PropTypes from "prop-types";
import styles from "./HighlightBlock.module.css";

import LightBox from "../../../LightBox/LightBox";
import useLightBox from "../../../LightBox/LightBoxHooks/useLightbox";

const HighlightBlock = ({
  isTextCenter,
  title1,
  title2,
  description1,
  description2,
  image1,
  image2,
  extraImages = [],
  imageAlt1,
  imageAlt2,
  extraImageAlts = [],
  hasLightbox=false,
  children,
}) => {
  const { isOpen, image, openLightBox, closeLightBox } = useLightBox();

  return (
    <>
      <section className={`${styles.section} animsec`}>
        <div className={styles.container}>
          {/* First Block */}
          {(title1 || description1) && (
            <div
              className={`animatedcont ${isTextCenter ? styles.textCenter : ""}`}
            >
              <h5 className={styles.title}>{title1}</h5>
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: description1 }}></p>
            </div>
          )}

          {image1 && (
            <div className="animatedcont">
              <img
                className={`${styles.images} ${hasLightbox ? "lb-img" : ""}`}
                src={image1}
                alt={imageAlt1}
                loading="lazy"
                onClick={() => hasLightbox && openLightBox(image1)}
              />
            </div>
          )}

          {/* Second block */}
          {children && (
            <div className="animatedcont">
              <div className={styles.cardContainer}>{children}</div>
            </div>
          )}

          {/* Third block */}
          {(title2 || description2) && (
            <div className="animatedcont">
              <h5 className={styles.title}>{title2}</h5>
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: description2 }}></p>
            </div>
          )}

          {/* Fourth block */}
          {image2 && (
            <div className="animatedcont">
              <img
                className={`${styles.images} ${hasLightbox ? "lb-img" : ""}`}
                src={image2}
                alt={imageAlt2}
                loading="lazy"
                onClick={() => hasLightbox && openLightBox(image2)}
              />
            </div>
          )}

          {/* Fifth block: Extra Images (Multiple) */}
          {extraImages.length > 0 &&
            extraImages.map((imgSrc, index) => (
              <div key={index} className="animatedcont">
                <img
                  className={`${styles.images} ${hasLightbox ? "lb-img" : ""}`}
                  src={imgSrc}
                  alt={extraImageAlts[index] || `Extra Image ${index + 1}`}
                  loading="lazy"
                  onClick={() => hasLightbox && openLightBox(imgSrc)}
                />
              </div>
            ))}
        </div>
      </section>

      {/* LightBox for thumbnail */}
      <LightBox isOpen={isOpen} image={image} onClose={closeLightBox} />
    </>
  );
};

HighlightBlock.propTypes = {
  isTextCenter: PropTypes.bool,
  title1: PropTypes.string,
  title2: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
  image1: PropTypes.string,
  image2: PropTypes.string,
  extraImages: PropTypes.arrayOf(PropTypes.string), // ✅ Array of image URLs
  imageAlt1: PropTypes.string,
  imageAlt2: PropTypes.string,
  extraImageAlts: PropTypes.arrayOf(PropTypes.string), // ✅ Array of alt texts
  hasLightbox: PropTypes.bool,
  children: PropTypes.node,
};

export default HighlightBlock;
