import React from "react";
import PropTypes from "prop-types";
import styles from "./HighlightBlock.module.css";

import LightBox from "../../../LightBox/LightBox";
import useLightBox from "../../../LightBox/LightBoxHooks/useLightbox";

const HighlightBlock5 = ({
    isTextCenter,
    imageSrc,
    imageAlt,
    title1,
    description1,
    title2,
    description2,
    hasLightbox = false,
}) => {
    const { isOpen, image, openLightBox, closeLightBox } = useLightBox();

    return (
        <>
            <section className={`${styles.section} animsec`}>
                <div className={styles.container}>
                    <div className={`animatedcont ${isTextCenter ? styles.textCenter : ""}`}>
                        <h5 className={styles.title}>{title1}</h5>
                        <p className={styles.description} dangerouslySetInnerHTML={{ __html: description1 }}></p>
                    </div>

                    {imageSrc && (
                        <div className="animatedcont">
                            <img
                                className={`${styles.images} ${hasLightbox ? "lb-img" : ""}`}
                                src={imageSrc}
                                alt={imageAlt}
                                loading="lazy"
                                onClick={() => hasLightbox && openLightBox(imageSrc)}
                            />
                        </div>
                    )}

                    <div className={`animatedcont`}>
                        <h5 className={styles.title}>{title2}</h5>
                        <p className={styles.description} dangerouslySetInnerHTML={{ __html: description2 }}></p>
                    </div>
                </div>
            </section>

            {/* LightBox for thumbnail */}
            <LightBox isOpen={isOpen} image={image} onClose={closeLightBox} />
        </>
    );
};

HighlightBlock5.propTypes = {
    isTextCenter: PropTypes.bool,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    title1: PropTypes.string,
    description1: PropTypes.string,
    title2: PropTypes.string,
    description2: PropTypes.string,
    hasLightbox: PropTypes.bool
};

export default HighlightBlock5;
